<template>
  <Head v-if="costMin">
    <title>
      Купить пятикомнатную квартиру в новостройке на Ботаническом саду в Москве
      от застройщика | ЖК VERY
    </title>
    <meta
      name="description"
      :content="
        'Продажа новых пятикомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:title"
      content="Купить пятикомнатную квартиру в новостройке на Ботаническом саду в Москве от застройщика | ЖК VERY"
    />
    <meta
      property="og:description"
      :content="
        'Продажа новых пятикомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>

  <FlatsSearch
    h1="Пятикомнатные квартиры"
    pre-rooms="5"
    @costMin="onCostMinEmits"
  >
  </FlatsSearch>
</template>

<script>
import FlatsSearch from "@/components/FlatsSearch.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    FlatsSearch,
  },

  data() {
    return {
      costMin: null,
    };
  },
  methods: {
    onCostMinEmits(costMin) {
      this.costMin = costMin;
    },
  },
};
</script>
